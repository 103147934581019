import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./styles/globals.css";
import "./App.css";

import HomePage from "./pages/HomePage";
import Attributes from "./pages/Attributes";
import Roadmap from "./pages/Roadmap";
import LandingPage from "./pages/landingPage/LandingPage";

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/sdjmfhbsdf/home" component={HomePage} />
          <Route exact path="/sdjmfhbsdf/attributes" component={Attributes} />
          <Route exact path="/sdjmfhbsdf/roadmap" component={Roadmap} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
