import React from "react";
import { Container, Nav, Navbar, Col, Row } from "react-bootstrap";
import "../styles/common.module.css";
import logo from "../assets/avatars/logo.png";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import "./MainNavbar.css";

const MainNavbar = () => {
  return (
    <Navbar expand={"xl"} style={{ backgroundColor: "#5bef5b" }}>
      <Container>
        <Navbar.Brand href="#home">
          <img
            alt=""
            src={logo}
            width="300"
            height="50"
            // className="d-inline-block align-top"
          />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Col>
              <LinkContainer to="/">
                <Nav.Link>Home</Nav.Link>
              </LinkContainer>
            </Col>
            <Col>
              <LinkContainer to="/attributes">
                <Nav.Link>Attributes</Nav.Link>
              </LinkContainer>
            </Col>
            <Col xl={{ span: 3 }}>
              <LinkContainer to="/">
                <Nav.Link>My Shibas</Nav.Link>
              </LinkContainer>
            </Col>
            <Col>
              <LinkContainer to="/roadmap">
                <Nav.Link>Roadmap</Nav.Link>
              </LinkContainer>
            </Col>
            <Col>
              <LinkContainer to="/">
                <Nav.Link>FAQ</Nav.Link>
              </LinkContainer>
            </Col>
            <Col xl={{ span: 3, offset: 3 }}>
              <LinkContainer to="/">
                <Nav.Link>Buy Shiba</Nav.Link>
              </LinkContainer>
            </Col>
            <Col>
              <LinkContainer to="/">
                <Nav.Link>Connect</Nav.Link>
              </LinkContainer>
            </Col>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MainNavbar;
