import React from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import styles from "../styles/common.module.css";
import sollamaslogodark from "../assets/avatars/sollamaslogodark.png";
import joinus from "../assets/avatars/joinus.png";
import { Link } from "react-router-dom";

const MainFooter = () => {
  return (
    <footer className="rootColor">
      <Container fluid>
        <Row style={{ paddingTop: "40px", paddingBottom: "20px" }}>
          <Col md={6}>
            <img src={sollamaslogodark} height="80" width="300" />
            <div style={{ padding: "20px" }}>
              <p>
                Your Shiba! Your Shiba! Your Shiba! Your Shiba! Your Shiba! Your
                Shiba! Your Shiba! Your Shiba! <br></br>
                Your Shiba! Your Shiba! Your Shiba! Your Shiba! Shiba! Your
                Shiba! Your Shiba!
              </p>
            </div>
            <div style={{ float: "left", padding: "20px" }}>
              <img src={joinus} alt="" height="50" width="150" />
            </div>
          </Col>
          <Col md={6}>
            <Container>
              <Row>
                <Col md={6} style={{ textAlign: "left" }}>
                  <h4 style={{ color: "#5bef5b" }}>ENDANGERED CLUB</h4>
                  <Nav style={{ textAlign: "left" }}>
                    <Link
                      className="col-md-12 col-sm-12 footlink"
                      to="/"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                      Home
                    </Link>
                    <Link
                      className="col-md-12 footlink"
                      to="/"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                      Attributes
                    </Link>
                    <Link
                      className="col-md-12 footlink"
                      to="/"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                      My Shiba
                    </Link>
                    <Link
                      className="col-md-12 col-sm-12 footlink" 
                      to="/roadmap"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                      Roadmap
                    </Link>
                    <Link
                      className="col-md-12 footlink"
                      to="/"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                      Buy a Shiba
                    </Link>
                  </Nav>
                </Col>
                <Col md={6} style={{ textAlign: "left" }}>
                  <h4 style={{ color: "#5bef5b" }}>SUPPORT</h4>
                  <Nav style={{ textAlign: "left" }}>
                    <Nav.Link
                      href="/"
                      className="col-md-12"
                      style={{ color: "#FFF" }}
                    >
                      FAQ
                    </Nav.Link>
                  </Nav>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default MainFooter;
