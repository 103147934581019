import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "../styles/common.module.css";
import hbtn1 from "../assets/buttons/hbtn1.png";
import hbtn2 from "../assets/buttons/hbtn2.png";
import hbtn3 from "../assets/buttons/hbtn3.png";
import cardsingle_nft from "../assets/avatars/cardsingle-nft.png";
import cardpack from "../assets/avatars/cardpack.png";
import prettyface from "../assets/avatars/prettyface.png";
import attributes from "../assets/avatars/attributes.png";
import rareboiz from "../assets/avatars/rareboiz.png";
import sollanallama from "../assets/avatars/sollanallama.png";
import blankmint from "../assets/avatars/blankmint.png";
import MainNavbar from "../component/MainNavbar";
import MainFooter from "../component/MainFooter";
import smp from "../assets/avatars/smp.png";
const HomePage = () => {
  return (
    <>
      <MainNavbar />
      <Container>
        <Row
          className="justify-content-md-center"
          style={{ paddingTop: "40px" }}
        >
          <div className="main">
            <h3>Your Shiba</h3>
            <p>
              Your Shiba! Your Shiba! Your Shiba! Your Shiba!Your Shiba! Your
              Shiba! Your Shiba! Your Shiba! <br></br>
              Your Shiba! Your Shiba! Your Shiba! Your Shiba!
            </p>
          </div>
        </Row>
        <Row
          className="justify-content-md-center"
          style={{ paddingBottom: "20px" }}
        >
          <Col md={6}>
            <img
              src={hbtn2}
              height="40"
              width="150"
              style={{ padding: "inherit" }}
            ></img>
            <img
              src={hbtn3}
              height="40"
              width="150"
              style={{ padding: "inherit" }}
            ></img>
          </Col>
        </Row>
      </Container>
      <Container fluid style={{ overflowX: "hidden" }}>
        <Row>
          <div className="slider">
            <div
              className="sliderRow"
              // style={{ backgroundImage: `url("${smp}")` }}
            >
              <img src={smp} alt="" />
            </div>
          </div>
        </Row>
      </Container>
      <Container>
        <div>
          <Row style={{ paddingTop: "40px", paddingBottom: "20px" }}>
            <Col md={12}>
              <div className="main">
                <h3>Your Shiba</h3>
                <p>
                  Your Shiba! Your Shiba! Your Shiba! Your Shiba!Your Shiba!
                  Your Shiba! Your Shiba! Your Shiba! <br></br>
                  Your Shiba! Your Shiba! Your Shiba! Your Shiba! Shiba! Your
                  Shiba! Your Shiba! <br></br>
                  Your Shiba! Your Shiba! Your Shiba! Your Shiba!
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container>
        <Row>
          <Col md={6}>
            <img src={cardsingle_nft} height="60%" width="60%" />
            <h2 style={{ fontWeight: "bold", color: "yellow" }}>Your Shiba!</h2>
            <p style={{ fontSize: "large" }}>
              1x Shiba NFT <br></br>4 SOL{" "}
              <span>
                <img src={hbtn1} alt="" height="20px" width="20px" />
              </span>
              <br></br>= 4 SOL per NFT
            </p>
          </Col>
          <Col md={6}>
            <img src={cardpack} height="60%" width="90%" />
            <h2 style={{ fontWeight: "bold", color: "yellow" }}>Your Shiba!</h2>
            <p style={{ fontSize: "large" }}>
              1x Shiba NFT <br></br>4 SOL{" "}
              <span>
                <img src={hbtn1} alt="" height="20px" width="20px" />
              </span>
              <br></br>= 4 SOL per NFT
            </p>
            <h2 style={{ fontWeight: "bold", color: "yellow" }}>SOLD OUT</h2>
          </Col>
        </Row>
        <Row style={{ paddingTop: "40px" }}>
          <Col md={{ span: 6, order: 1 }} xs={{ span: 12, order: 2 }}>
            <div className="main">
              <h3>Your Shiba</h3>
              <p>
                Your Shiba! Your Shiba! Your Shiba! Your Shiba!Your Shiba! Your
                Shiba! Your Shiba! Your Shiba! <br></br>
                Your Shiba! Your Shiba! Your Shiba! Your Shiba! Shiba! Your
                Shiba! Your Shiba! <br></br>
                Your Shiba! Your Shiba! Your Shiba! Your Shiba!
              </p>
            </div>
          </Col>
          <Col md={{ span: 6, order: 2 }} xs={{ span: 12, order: 1 }}>
            <img src={prettyface} height="90%" width="90%" max-height="750px" />
          </Col>
        </Row>
        <Row style={{ paddingTop: "40px" }}>
          <Col md={6}>
            <img src={attributes} height="90%" width="90%" max-height="750px" />
          </Col>
          <Col md={6}>
            <div className="main">
              <h3>Your Shiba</h3>
              <p>
                Your Shiba! Your Shiba! Your Shiba! Your Shiba!Your Shiba! Your
                Shiba! Your Shiba! Your Shiba! <br></br>
                Your Shiba! Your Shiba! Your Shiba! Your Shiba! Shiba! Your
                Shiba! Your Shiba! <br></br>
                Your Shiba! Your Shiba! Your Shiba! Your Shiba!
              </p>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "40px", paddingBottom: "20px" }}>
          <Col md={{ span: 6, order: 1 }} xs={{ span: 12, order: 2 }}>
            <div className="main">
              <h3>Your Shiba</h3>
              <p>
                Your Shiba! Your Shiba! Your Shiba! Your Shiba!Your Shiba! Your
                Shiba! Your Shiba! Your Shiba! <br></br>
                Your Shiba! Your Shiba! Your Shiba! Your Shiba! Shiba! Your
                Shiba! Your Shiba! <br></br>
                Your Shiba! Your Shiba! Your Shiba! Your Shiba!
              </p>
            </div>
          </Col>
          <Col md={{ span: 6, order: 1 }} xs={{ span: 12, order: 1 }}>
            <img src={rareboiz} height="100%" width="90%" />
          </Col>
        </Row>
        <Row style={{ paddingTop: "40px", paddingBottom: "20px" }}>
          <Col md={6}>
            <img src={sollanallama} height="90%" width="80%" />
          </Col>
          <Col md={6}>
            <div className="main">
              <h3>Your Shiba</h3>
              <p>
                Your Shiba! Your Shiba! Your Shiba! Your Shiba!Your Shiba! Your
                Shiba! Your Shiba! Your Shiba! <br></br>
                Your Shiba! Your Shiba! Your Shiba! Your Shiba! Shiba! Your
                Shiba! Your Shiba! <br></br>
                Your Shiba! Your Shiba! Your Shiba! Your Shiba!
              </p>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "40px", paddingBottom: "20px" }}>
          <Col md={12}>
            <img src={blankmint} height="70%" width="50%" />
            <div className="mainPad10">
              <img src={hbtn2} height="40" width="150" />
            </div>
          </Col>
        </Row>
      </Container>
      <MainFooter />
    </>
  );
};

export default HomePage;
